import React from "react";
import { useTranslation } from "react-i18next";
import Container from "./Container";
import BlogItem from "./BlogItem";
import Colors from "../utils/colors";

const SimilarReports = () => {
  const { t } = useTranslation();
  return (
    <Container backgroundColor={Colors.safiaBlue}>
      <h2 className="text-h2mobile sm:text-h2desktop">
        {t("relatedArticles")}
      </h2>
      <div className="flex flex-row flex-wrap mt-12 justify-between">
        {/* <BlogItem />
        <BlogItem />
        <BlogItem />
        <BlogItem /> */}
        <p className="text-white text-body">{t("noSimilarReports")}</p>
      </div>
    </Container>
  );
};

export default SimilarReports;
