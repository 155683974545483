import React from "react";
import { useTranslation } from "react-i18next";
import Container from "./Container";
import TimmPortrait from "../images/team/timm.png";
import PeterPortrait from "../images/team/peter.png";

const SinglePersonItem = ({
  imgSrc,
  name,
  position,
  tel,
  email,
  alternate,
  telString,
}) => (
  <div>
    <div className="flex justify-start">
      <img
        className="h-20 w-20 rounded-full mb-4"
        src={imgSrc}
        alt="Kontakt Profilbild"
      />
    </div>
    <div className="text-left">
      <h4
        style={{ fontFamily: "Bold" }}
        className={`${
          alternate ? "text-white" : "text-safiaBlue"
        } text-h4desktop mb-0`}
      >
        {name}
      </h4>
      <p
        className={`${
          alternate ? "text-white" : "text-safiaBlue"
        } text-h4desktop mb-0`}
      >
        {position}
      </p>
    </div>
    <div className="text-left mt-2">
      <div
        className={`${
          alternate ? "text-white" : "text-safiaBlue"
        } mb-0 text-body`}
      >
        T:{" "}
        <a className="no-underline" href={`tel:${tel}`}>
          <span>{telString}</span>
        </a>
      </div>
      <div
        className={`${
          alternate ? "text-white" : "text-safiaBlue"
        } mb-0 text-body`}
      >
        M:{" "}
        <a href={`mailto:${email}`}>
          <span>{email}</span>
        </a>
      </div>
    </div>
  </div>
);

const ContactUs = ({ alternate }) => {
  const { t } = useTranslation();
  const ContactData = [
    {
      name: "Dr. Peter Carl",
      position: t("ceo"),
      telString: "+ 49 (0) 172 4260 922",
      tel: "+491724260922",
      email: "carl@safia.tech",
      imgSrc: PeterPortrait,
    },
    {
      name: "Dr. Timm Schwaar",
      position: t("ceo"),
      telString: "+ 49 (0) 162 7323 405",
      tel: "+491627323405",
      email: "schwaar@safia.tech",
      imgSrc: TimmPortrait,
    },
  ];
  return (
    <Container backgroundColor={alternate ? "#140E55" : "#fff"}>
      <h2
        style={{ fontFamily: "Bold" }}
        className={`${
          alternate ? "text-white" : "text-safiaBlue"
        } text-h2mobile sm:text-h2desktop`}
      >
        {t("youHaveQuestions")}
      </h2>
      <p className={`${alternate ? "text-white" : "text-safiaBlue"} mt-6`}>
        {t("pleaseFeelFreeToContactUs")}
      </p>
      <div className="flex flex-row flex-wrap mt-8">
        {ContactData.map(
          ({ name, position, tel, email, imgSrc, telString }, index) => (
            <div
              key={name}
              className={`w-full md:w-6/12 lg:w-4/12 ${
                index !== 0 && "mt-12"
              } md:mt-0`}
            >
              <SinglePersonItem
                imgSrc={imgSrc}
                name={name}
                position={position}
                tel={tel}
                email={email}
                alternate={alternate}
                telString={telString}
              />
            </div>
          )
        )}
      </div>
    </Container>
  );
};

export default ContactUs;
