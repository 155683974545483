import React, { useContext } from "react";
import { graphql } from "gatsby";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { de, enUS } from "date-fns/locale";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Container from "../components/Container";
import ContactUs from "../components/ContactUs";
import SimilarReports from "../components/SimilarReports";
import NewsletterSubscribe from "../components/NewsletterSubscribe";
import { LanguageContext } from "../context";

export const query = graphql`
  query($slug: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        language
        date
        author
      }
      html
    }
  }
`;

const BlogPost = (props) => {
  const { getLanguage } = useContext(LanguageContext);
  const { t } = useTranslation();
  return (
    <Layout>
      <SEO title={props.data.markdownRemark.frontmatter.title} />
      <div className="w-full bg-safiaBlue h-18"></div>
      <Container backgroundColor="#fff">
        <h1 className="text-h1mobile sm:text-h1desktop text-safiaBlue w-full lg:w-9/12">
          {props.data.markdownRemark.frontmatter.title}
        </h1>
        <div className="text-safiaBlue text-body font-safiaBold mb-4 mt-10 mb-10">
          {format(
            new Date(props.data.markdownRemark.frontmatter.date),
            "dd.MMMM.yyyy",
            {
              locale: getLanguage() === "DE" ? de : enUS,
            }
          )}{" "}
          <span className="text-screamingGreen">•</span>{" "}
          {props.data.markdownRemark.frontmatter.author}
        </div>
        <div
          className="w-full lg:w-9/12"
          dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }}
        ></div>
        <div className="mt-12 flex flex-row items-center">
          <span className="text-body font-safiaBold text-safiaBlue">
            {t("shareThisArticle")}
          </span>
          <a
            target="_blank"
            href={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`}
            rel="noreferrer"
          >
            <svg
              width="36px"
              height="36px"
              viewBox="0 0 36 36"
              version="1.1"
              className="mr-6 ml-4 shareicon"
            >
              <title>{t("sharelinkedin")}</title>
              <g
                id="Page-1"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="60-Article---Desktop-HD"
                  transform="translate(-371.000000, -1193.000000)"
                >
                  <rect
                    id="Rectangle"
                    fill="#FFFFFF"
                    x="0"
                    y="71"
                    width="1440"
                    height="1238"
                  ></rect>
                  <g
                    id="linkedin-share"
                    transform="translate(371.000000, 1193.000000)"
                  >
                    <circle id="Oval" cx="18" cy="18" r="18"></circle>
                    <g
                      id="Icons/brand/linkedin"
                      transform="translate(8.000000, 8.000000)"
                    >
                      <rect
                        id="Box-24pt"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                      ></rect>
                      <path
                        d="M17.0391667,17.0433333 L14.0775,17.0433333 L14.0775,12.4025 C14.0775,11.2958333 14.055,9.87166667 12.5341667,9.87166667 C10.99,9.87166667 10.7541667,11.0758333 10.7541667,12.3208333 L10.7541667,17.0433333 L7.7925,17.0433333 L7.7925,7.5 L10.6375,7.5 L10.6375,8.80083333 L10.6758333,8.80083333 C11.0733333,8.05083333 12.04,7.25916667 13.4841667,7.25916667 C16.485,7.25916667 17.04,9.23416667 17.04,11.805 L17.04,17.0433333 L17.0391667,17.0433333 Z M4.4475,6.19416667 C3.49416667,6.19416667 2.72833333,5.4225 2.72833333,4.47333333 C2.72833333,3.525 3.495,2.75416667 4.4475,2.75416667 C5.3975,2.75416667 6.1675,3.525 6.1675,4.47333333 C6.1675,5.4225 5.39666667,6.19416667 4.4475,6.19416667 Z M5.9325,17.0433333 L2.9625,17.0433333 L2.9625,7.5 L5.9325,7.5 L5.9325,17.0433333 Z M18.5208333,0 L1.47583333,0 C0.66,0 0,0.645 0,1.44083333 L0,18.5591667 C0,19.3558333 0.66,20 1.47583333,20 L18.5183333,20 C19.3333333,20 20,19.3558333 20,18.5591667 L20,1.44083333 C20,0.645 19.3333333,0 18.5183333,0 L18.5208333,0 Z"
                        id="🎨-Style"
                        fill="#FFFFFF"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </a>
          <a
            rel="noreferrer"
            href={`https://twitter.com/intent/tweet?text=${props.data.markdownRemark.frontmatter.title}%20${window.location.href}`}
          >
            <svg
              width="36px"
              height="36px"
              viewBox="0 0 36 36"
              version="1.1"
              className="shareicon"
            >
              <title>{t("sharetwitter")}</title>
              <g
                id="Page-1"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="60-Article---Desktop-HD"
                  transform="translate(-432.000000, -1193.000000)"
                >
                  <rect
                    id="Rectangle"
                    fill="#FFFFFF"
                    x="0"
                    y="71"
                    width="1440"
                    height="1238"
                  ></rect>
                  <g
                    id="twitter-share"
                    transform="translate(432.000000, 1193.000000)"
                  >
                    <circle id="Oval-Copy" cx="18" cy="18" r="18"></circle>
                    <g
                      id="Icons/brand/linkedin-Copy"
                      transform="translate(8.000000, 8.000000)"
                    >
                      <rect
                        id="Box-24pt"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                      ></rect>
                      <path
                        d="M19.960835,3.65142428 C19.2233657,3.9837836 18.4358985,4.21019805 17.6067664,4.31357974 C18.4517312,3.79154492 19.1017041,2.96876335 19.4091913,1.98706447 C18.6167243,2.46125323 17.7384276,2.80642846 16.8034665,2.99866715 C16.056831,2.17930316 14.9927086,1.66666667 13.8110912,1.66666667 C11.5470189,1.66666667 9.71126203,3.54889698 9.71126203,5.8677227 C9.71126203,6.20093642 9.74876047,6.52133422 9.81709095,6.82806172 C6.40889963,6.66316365 3.38819216,4.98513354 1.36660972,2.44843731 C1.01079122,3.06530989 0.811632849,3.78214658 0.811632849,4.56306283 C0.811632849,6.02407683 1.53660264,7.30823123 2.63489021,8.06266127 C1.96241823,8.04044702 1.32994459,7.85077152 0.778300904,7.53635447 L0.778300904,7.58847251 C0.778300904,9.62620256 2.18907546,11.3255925 4.06649723,11.7126331 C3.7223449,11.8074708 3.35902671,11.8587345 2.98654223,11.8587345 C2.72488646,11.8587345 2.47406358,11.8331026 2.2232407,11.7852566 C2.74905212,13.4538883 4.26065581,14.6705456 6.05974751,14.7047214 C4.65980584,15.8316673 2.8857131,16.5032211 0.974959377,16.5032211 C0.649972918,16.5032211 0.325819758,16.48357 0,16.4459767 C1.82409066,17.6370021 3.97316778,18.3333333 6.29723762,18.3333333 C13.8419233,18.3333333 17.9625849,11.9287948 17.9625849,6.38377676 C17.9625849,6.20520839 17.9625849,6.02493122 17.9500854,5.84550845 C18.7508854,5.25683088 19.4500229,4.51265358 20,3.66851216 L19.960835,3.65142428 Z"
                        id="🎨-Style"
                        fill="#FFFFFF"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </a>
        </div>
      </Container>
      <SimilarReports />
      <ContactUs />
      <NewsletterSubscribe />
    </Layout>
  );
};

export default BlogPost;
